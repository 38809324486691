
import { defineComponent } from "vue";
import {
  Col,
  Row,
  Input,
  Button,
  Cascader,
  Select,
  Table,
  Pagination,
  Popover,
  message,
  Switch,
  Checkbox,
} from "ant-design-vue";
import {
  UnorderedListOutlined,
  SettingOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  FileAddFilled,
  StopOutlined,
  ReloadOutlined,
  ColumnHeightOutlined,
  TagsFilled,
  PictureFilled,
  SyncOutlined,
  SearchOutlined,
} from "@ant-design/icons-vue";
import AddModal from "@/components/cust/AddModal.vue";
import UpdateModal from "@/components/cust/UpdateModal.vue";
import {
  disableCustById,
  allBrandList,
  brandDownList,
  exportSingleProductList,
  listSingleProduct,
  listCustType,
  listCust,
  categoryList,
} from "../../../api/terminalList.js";
const { Option } = Select;
import { custColumns } from "@/api/fixedData.js";
import PreviewModal from "@/components/singleProduct/PreviewModal.vue";
let { Group: CheckboxGroup } = Checkbox;

export default defineComponent({
  components: {
    Col,
    Row,
    Select,
    Input,
    Button,
    Cascader,
    SettingOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    CloudUploadOutlined, //上传图标
    CloudDownloadOutlined, //下载图标
    FileAddFilled, //新建图标
    StopOutlined, //电源图标
    TagsFilled,
    UnorderedListOutlined,
    Table,
    Pagination,
    Popover,
    PreviewModal,
    Switch,
    aCheckbox: Checkbox,
    aCheckboxGroup: CheckboxGroup,
    PictureFilled,
    SyncOutlined,
    SearchOutlined,
    AddModal,
    UpdateModal,
    Option,
  },

  data() {
    return {
      show: false,
      categoryOptions: [],
      brandOptions: [],
      //数据相关
      custNameOrCode: "",
      unifiedCode: "",
      address: "",
      custType: null,
      //table的数据
      data: [],
      //table的列名字
      columns: [],
      //复选框的选择结果
      selectedRowKeys: [],
      // 当前页数
      pageCurrent: 1,
      // 每页数据数量
      pageSize: 10,
      pageNum: 1,
      //总数据量
      total: 0,
      tableHeight: "400px",
      searchFlag: false,
      //table的布局
      scroll: { x: "100%" },
      //table的loading
      loading: true,
      //批量操作按钮加载
      batchLoading: false,
      //table的样式
      batchStyle: "middle",
      //客户类型数组
      listBaseValue: [],
      showUpdateModal: false,
      //拓展字段
      customData: [],
    };
  },
  methods: {
    addCustAlert() {
      (this as any).$refs.AddModal.showModal();
    },
    //子传父重新获取客户数据
    reloadCustList(refresh) {
      if (refresh) {
        this.getCust();
      }
    },
    //复选结果
    onSelectChange(selectedRowKeys: any) {
      //
      this.selectedRowKeys = selectedRowKeys;
    },
    //更换每页显示数量
    onShowSizeChange(current: any, pageSize: any) {
      this.pageNum = 1;
      this.pageCurrent = 1;
      this.pageSize = pageSize;
      this.getCust();
    },
    //弹出Popover 进行筛选
    handelColumns() {
      (this as any).columns = custColumns;
    },
    inputChange(item) {
      if (item.checked) {
        (this as any).customData.forEach((element, index) => {
          if (element.key == item.key) {
            (this as any).keepCustomData.splice(index, 0, element);
          }
        });
      } else {
        for (let i = 0; i < (this as any).keepCustomData.length; i++) {
          if (!(this as any).keepCustomData[i].checked) {
            (this as any).keepCustomData.splice(i, 1);
          }
        }
      }
      this.handelColumns();
    },
    //跳转到终端详情页面
    terminalDetails(record) {
      if ((this as any).$refs.UpdateModal) {
        (this as any).$refs.UpdateModal.showModal(record);
      }
    },
    //计算终端状态
    computedStatus(status) {
      switch (status) {
        case false:
          return "禁用";
        case true:
          return "启用";
        default:
          return "系统错误";
      }
    },
    //禁用终端 单个
    disableCustById(id, status) {
      status = status == "0" ? "1" : "0";
      let params = {
        ids: [id],
        status: status,
      };
      disableCustById(params).then((res) => {
        if (res.data.success) {
          message.success("客户状态更新成功");
          this.getCust();
        }
      });
    },
    //批量禁用
    disableBatch(id) {
      let params = {
        ids: [...id],
        status: 0,
      };
      disableCustById(params).then((res) => {
        //
        if (res.data.success) {
          message.success("批量禁用成功");
        }
        // else {
        //   message.error(res.data.data);
        // }
        this.getCust();
      });
    },
    //获取客户列表
    getCust() {
      this.loading = true;
      listCust({
        custNameOrCode: this.custNameOrCode,
        unifiedCode: this.unifiedCode,
        address: this.address,
        custType: this.custType==='全部'?'':this.custType,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      })
        .then((res) => {
          if (res.data.success) {
            let result = res.data.data;
            this.total = res.data.total;
            this.data = result;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //重置
    reset() {
      this.custNameOrCode = "";
      this.unifiedCode = "";
      this.address = "";
      this.custType = null;
      this.search();
      this.searchFlag = false;
    },
    //搜索
    search() {
      this.searchFlag = true;
      this.pageNum = 1;
      this.getCust();
      this.pageCurrent = 1;
    },
    //计算当前是否禁用状态
    computeStatus(status) {
      if (status == 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    //监听页码和页面数量变化
    pageCurrent() {
      //

      if (this.searchFlag === false) {
        this.custNameOrCode = "";
        this.unifiedCode = "";
        this.address = "";
        this.custType = null;
      }
      this.selectedRowKeys = [];
      this.pageNum = this.pageCurrent;
      this.getCust();
    },
  },
  mounted() {
    //获取列表信息
    (this as any).columns = custColumns;
    if ((this as any).columns.length != 6) {
      (this as any).columns.push({
        title: "状态",
        slots: { customRender: "handle" },
        align: "left",
        fixed: "right",
        width: 50,
      });
    }
    this.getCust();
  },
  created() {
    //获取客户类型下拉列表
    listCustType().then((res: any) => {
      res.data.data = [
        // {
        //   name: "全部",
        // },
        ...res.data.data,
      ];
      if (res.data.success) {
        this.listBaseValue = res.data.data;
      }
    });
  },
});
