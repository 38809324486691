import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-192b752c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "outcell",
  style: {"padding":"0 220px 0 138px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DecorateModalTitle = _resolveComponent("DecorateModalTitle")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Form_item = _resolveComponent("Form-item")!
  const _component_Option = _resolveComponent("Option")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Modal, {
      visible: _ctx.visible,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.visible) = $event)),
      centered: "",
      onOk: _ctx.handleOk,
      class: "modal AddModal",
      confirmLoading: _ctx.confirmLoading,
      width: "800px",
      okButtonProps: { disabled: _ctx.formState.categoryName == '' }
    }, {
      title: _withCtx(() => [
        _createVNode(_component_DecorateModalTitle, { modalTitle: "新增客户" })
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Form, {
            model: _ctx.formState,
            rules: _ctx.rules,
            ref: "formRef",
            "label-col": { style: { width: '110px' } }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Form_item, {
                label: "客户名称",
                name: "custName"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Input, {
                    value: _ctx.formState.custName,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.custName) = $event)),
                    placeholder: "请输入",
                    style: {"width":"330px"}
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_Form_item, {
                label: "客户编码",
                name: "custCode"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Input, {
                    value: _ctx.formState.custCode,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.custCode) = $event)),
                    placeholder: "请输入",
                    style: {"width":"330px"}
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_Form_item, {
                label: "社会信用统一编码",
                name: "unifiedCode"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Input, {
                    value: _ctx.formState.unifiedCode,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.unifiedCode) = $event)),
                    placeholder: "请输入",
                    style: {"width":"330px"}
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_Form_item, {
                label: "地址",
                name: "address"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Input, {
                    value: _ctx.formState.address,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.address) = $event)),
                    placeholder: "请输入",
                    style: {"width":"330px"}
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_Form_item, {
                label: "客户类型",
                name: "custType"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Select, {
                    value: _ctx.formState.custType,
                    "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.custType) = $event)),
                    allowClear: "",
                    style: {"width":"330px"},
                    placeholder: "请选择"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listBaseValue, (item, index) => {
                        return (_openBlock(), _createBlock(_component_Option, {
                          key: index,
                          value: item.name
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules"])
        ])
      ]),
      _: 1
    }, 8, ["visible", "onOk", "confirmLoading", "okButtonProps"])
  ]))
}