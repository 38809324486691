
import { defineComponent } from "vue";
import {
  Modal,
  Form,
  Input,
  Cascader,
  Upload,
  message,
  Select,
} from "ant-design-vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import {
  addCategory,
  categoryList,
  saveCust,
  listCustType,
} from "@/api/terminalList.js";
import CateforySelectReduce from "@/components/singleProduct/CateforySelectReduce.vue";
import DecorateModalTitle from "@/components/modification/DecorateModalTitle.vue";
const checkCode = async(rule, value) => {
    var reg = new RegExp("^[A-Za-z0-9]+$");
    var value = value.trim();
    if (!reg.test(value)&&value!='') {
        return Promise.reject('仅支持字母和数字');
    }
}
const { Item } = Form;
export default defineComponent({
  components: {
    Modal,
    Select,
    Option,
    Form,
    FormItem: Item,
    Input,
    Cascader,
    Upload,
    PlusOutlined,
    CateforySelectReduce,
    DecorateModalTitle,
  },
  props: {
    brandList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      visible: false,
      //客户类型数组
      listBaseValue: [],
      formState: {
        custCode: "",
        custName: "",
        unifiedCode: "",
        address: "",
        custType: "",
      },
      rules: {
        custCode: [
          {
            validator: checkCode,
            trigger: "blur",
          },
          { min: 0, max: 50, message: "最多50字符", trigger: "blur" },
        ],
        custName: [
          {
            required: true,
            message: "请输入客户名称",
            trigger: "blur",
          },
          { min: 0, max: 50, message: "最多50字符", trigger: "blur" },
        ],
        unifiedCode: [
          {
            required: false,
            validator: checkCode,
            trigger: "blur",
          },
          { min: 0, max: 50, message: "最多50字符", trigger: "blur" },
        ],
        address: [
          {
            required: false,
            message: "请输入地址",
            trigger: "blur",
          },
          { min: 0, max: 255, message: "最多255字符", trigger: "blur" },
        ],
        custType: [
          {
            required: true,
            message: "请选择客户类型",
            trigger: "blur",
          },
        ],
      },
      confirmLoading: false,
    };
  },
  methods: {
    showModal() {
      this.formState = {
        custCode: "",
        custName: "",
        unifiedCode: "",
        address: "",
        custType: "",
      };
      this.visible = true;
      (this as any).$refs.formRef.resetFields();
    },
    handleOk() {
      (this as any).$refs.formRef.validate().then(() => {
        this.confirmLoading = true;
        saveCust({
          custCode: this.formState.custCode,
          custName: this.formState.custName,
          unifiedCode: this.formState.unifiedCode,
          address: this.formState.address,
          custType: this.formState.custType,
        })
          .then((result) => {
            //新增结束通知父组件重新获取数据
            let refresh = true;
            this.$emit("myevent", refresh);
            if (result.data.success) {
              message.success("新增客户成功");
              this.confirmLoading = false;
              this.visible = false;
            } else {
              this.confirmLoading = false;
            }

            // setTimeout(() => {
            //   this.$router.go(0);
            // }, 1000);
          })
          .catch(() => {
            this.confirmLoading = false;
          });
      });
    },
  },
  mounted() {},
  created() {
    //获取客户类型下拉列表
    listCustType().then((res: any) => {
      if (res.data.success) {
        this.listBaseValue = res.data.data;
      }
    });
  },
});
