<template>
  <div>
    <Modal
      v-model:visible="visible"
      centered
      @ok="handleOk"
      class="modal UpdateModal"
      :confirmLoading="confirmLoading"
      width="800px"
      :okButtonProps="{ disabled: formState.categoryName == '' }"
      destroyOnClose
    >
      <template #title>
        <DecorateModalTitle modalTitle="编辑客户" />
      </template>
      <div class="outcell" style="padding: 0 220px 0 138px">
        <Form
          :model="formState"
          :rules="rules"
          ref="formRef"
          :label-col="{ style: { width: '110px' } }"
        >
          <Form-item label="客户名称" name="custName">
            <Input
              disabled
              v-model:value="formState.custName"
              placeholder="请输入"
              style="width: 330px"
            />
          </Form-item>
          <Form-item label="客户编码" name="custCode">
            <Input
              disabled
              v-model:value="formState.custCode"
              placeholder="请输入"
              style="width: 330px"
            />
          </Form-item>
          <Form-item label="社会信用统一编码" name="unifiedCode">
            <Input
              v-model:value="formState.unifiedCode"
              placeholder="请输入"
              style="width: 330px"
            />
          </Form-item>
          <Form-item label="地址" name="address">
            <Input
              v-model:value="formState.address"
              placeholder="请输入"
              style="width: 330px"
            />
          </Form-item>
          <Form-item label="客户类型" name="custType">
            <Select
              v-model:value="formState.custType"
              allowClear
              style="width: 100%"
              placeholder="请选择"
            >
              <Option
                v-for="(item, index) in listBaseValue"
                :key="index"
                :value="item.name"
                >{{ item.name }}</Option
              >
            </Select>
          </Form-item>
        </Form>
      </div>
    </Modal>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import {
  Modal,
  Form,
  Input,
  Cascader,
  Upload,
  message,
  Select,
} from "ant-design-vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import {
  addCategory,
  categoryList,
  saveCust,
  listCustType,
  updateCust,
} from "@/api/terminalList.js";
import CateforySelectReduce from "@/components/singleProduct/CateforySelectReduce.vue";
import DecorateModalTitle from "@/components/modification/DecorateModalTitle.vue";
const { Item } = Form;
export default defineComponent({
  components: {
    Modal,
    Select,
    Option,
    Form,
    FormItem: Item,
    Input,
    Cascader,
    Upload,
    PlusOutlined,
    CateforySelectReduce,
    DecorateModalTitle,
  },
  data() {
    return {
      visible: false,
      //客户类型数组
      listBaseValue: [],
      formState: {
        custCode: "",
        custName: "",
        unifiedCode: "",
        address: "",
        custType: "",
      },
      rules: {
        custCode: [
          {
            message: "请输入客户编码",
            trigger: "blur",
          },
          { min: 0, max: 50, message: "最多50字符", trigger: "blur" },
        ],
        custName: [
          {
            required: true,
            message: "请输入客户名称",
            trigger: "blur",
          },
          { min: 0, max: 50, message: "最多50字符", trigger: "blur" },
        ],
        unifiedCode: [
          {
            required: false,
            message: "请输入社会信用统一编码",
            trigger: "blur",
          },
          { min: 0, max: 50, message: "最多50字符", trigger: "blur" },
        ],
        address: [
          {
            required: false,
            message: "请输入地址",
            trigger: "blur",
          },
          { min: 0, max: 255, message: "最多255字符", trigger: "blur" },
        ],
        custType: [
          {
            required: true,
            message: "请选择客户类型",
            trigger: "blur",
          },
        ],
      },
      confirmLoading: false,
    };
  },
  methods: {
    showModal(cust) {
      this.formState.id = cust.id;
      this.formState.custCode = cust.custCode;
      this.formState.custName = cust.custName;
      this.formState.unifiedCode = cust.unifiedCode;
      this.formState.address = cust.address;
      this.formState.custType = cust.custType;
      this.visible = true;
      setTimeout(() => {
        this.$refs.formRef.resetFields();
      }, 0);
    },
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true;
        updateCust({
          id: this.formState.id,
          custCode: this.formState.custCode,
          custName: this.formState.custName,
          unifiedCode: this.formState.unifiedCode,
          address: this.formState.address,
          custType: this.formState.custType,
        })
          .then((result) => {
            //新增结束通知父组件重新获取数据
            let refresh = true;
            this.$emit("myevent", refresh);
            if (result.data.success) {
              message.success("编辑客户成功");
              this.confirmLoading = false;
              this.visible = false;
            } else {
              this.confirmLoading = false;
            }

            // setTimeout(() => {
            //   this.$router.go(0);
            // }, 1000);
          })
          .catch(() => {
            this.confirmLoading = false;
          });
      });
    },
  },
  mounted() {},
  created() {
    //获取客户类型下拉列表
    listCustType().then((res) => {
      if (res.data.success) {
        this.listBaseValue = res.data.data;
      }
    });
  },
});
</script>
<style lang="scss" scoped>
.modal {
  border-radius: 20px;
}
input {
  width: 70%;
}
.tips {
  color: #ccc;
  text-align: center;
}
.textarea {
  border-radius: 5px;
  border-color: #e1e1e1;
}
.positionBox {
  position: relative;
  > span {
    position: absolute;
    bottom: -27px;
    left: 80px;
  }
}
</style>
<style lang="scss" >
.UpdateModal .ant-select-selection-placeholder {
  height: 40px !important;
  line-height: 40px !important;
}
.UpdateModal .ant-select-selector {
  height: 40px !important;
  line-height: 40px !important;
}
.UpdateModal .ant-select-selection-item {
  height: 40px !important;
  line-height: 40px !important;
}
.UpdateModal input {
  width: 240px;
  height: 40px;
  line-height: 40px;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
}
</style>